// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hyper$OrcaPaymentPage from "./Hyper.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "../Utilities/ErrorUtils.bs.js";
import * as OrcaLogger$OrcaPaymentPage from "../orca-log-catcher/OrcaLogger.bs.js";

function loadHyper(str, option) {
  return Promise.resolve(Hyper$OrcaPaymentPage.make(str, option, undefined));
}

function loadStripe(str, option) {
  ErrorUtils$OrcaPaymentPage.manageErrorWarning("DEPRECATED_LOADSTRIPE", undefined, OrcaLogger$OrcaPaymentPage.defaultLoggerConfig);
  return loadHyper(str, option);
}

window.Hyper = Hyper$OrcaPaymentPage.make;

window.Hyper.init = Hyper$OrcaPaymentPage.make;

var isWordpress = window.wp !== null;

if (!isWordpress) {
  window.Stripe = Hyper$OrcaPaymentPage.make;
}

export {
  loadHyper ,
  loadStripe ,
  isWordpress ,
}
/*  Not a pure module */
