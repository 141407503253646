// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as SessionsType$OrcaPaymentPage from "./SessionsType.bs.js";

function getLabel($$var) {
  switch ($$var) {
    case "Default" :
        return "plain";
    case "Buy" :
        return "buy";
    case "Donate" :
        return "donate";
    case "Checkout" :
        return "checkout";
    case "Subscribe" :
        return "subscribe";
    case "Book" :
        return "book";
    case "Pay" :
        return "pay";
    case "Order" :
        return "order";
    
  }
}

var defaultTokenizationData = {
  token: ""
};

var defaultPaymentMethodData_info = {};

var defaultPaymentMethodData_tokenizationData = {};

var defaultPaymentMethodData = {
  description: "",
  info: defaultPaymentMethodData_info,
  tokenizationData: defaultPaymentMethodData_tokenizationData,
  type: ""
};

function getTokenizationData(str, dict) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    return {
                            token: Utils$OrcaPaymentPage.getString(json, "token", "")
                          };
                  })), defaultTokenizationData);
}

function getPaymentMethodData(str, dict) {
  return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(dict[str], Core__JSON.Decode.object), (function (json) {
                    return {
                            description: Utils$OrcaPaymentPage.getString(json, "description", ""),
                            info: Utils$OrcaPaymentPage.getJsonFromDict(json, "info", {}),
                            tokenizationData: Utils$OrcaPaymentPage.getJsonFromDict(json, "tokenizationData", {}),
                            type: Utils$OrcaPaymentPage.getString(json, "type", "")
                          };
                  })), defaultPaymentMethodData);
}

function itemToObjMapper(dict) {
  return {
          paymentMethodData: getPaymentMethodData("paymentMethodData", dict)
        };
}

function jsonToPaymentRequestDataType(paymentRequest, jsonDict) {
  paymentRequest.allowedPaymentMethods = Utils$OrcaPaymentPage.getArray(jsonDict, "allowed_payment_methods").map(function (json) {
        return Utils$OrcaPaymentPage.transformKeys(json, "CamelCase");
      });
  paymentRequest.transactionInfo = Utils$OrcaPaymentPage.transformKeys(Utils$OrcaPaymentPage.getJsonFromDict(jsonDict, "transaction_info", null), "CamelCase");
  paymentRequest.merchantInfo = Utils$OrcaPaymentPage.transformKeys(Utils$OrcaPaymentPage.getJsonFromDict(jsonDict, "merchant_info", null), "CamelCase");
  return paymentRequest;
}

function billingContactItemToObjMapper(dict) {
  return {
          address1: Utils$OrcaPaymentPage.getString(dict, "address1", ""),
          address2: Utils$OrcaPaymentPage.getString(dict, "address2", ""),
          address3: Utils$OrcaPaymentPage.getString(dict, "address3", ""),
          administrativeArea: Utils$OrcaPaymentPage.getString(dict, "administrativeArea", ""),
          countryCode: Utils$OrcaPaymentPage.getString(dict, "countryCode", ""),
          locality: Utils$OrcaPaymentPage.getString(dict, "locality", ""),
          name: Utils$OrcaPaymentPage.getString(dict, "name", ""),
          phoneNumber: Utils$OrcaPaymentPage.getString(dict, "phoneNumber", ""),
          postalCode: Utils$OrcaPaymentPage.getString(dict, "postalCode", ""),
          sortingCode: Utils$OrcaPaymentPage.getString(dict, "sortingCode", "")
        };
}

var baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0
};

function getPaymentDataFromSession(sessionObj, componentName) {
  var gpayobj = sessionObj !== undefined ? sessionObj : SessionsType$OrcaPaymentPage.defaultToken;
  var paymentDataRequest = Object.assign({}, baseRequest);
  paymentDataRequest.allowedPaymentMethods = Utils$OrcaPaymentPage.arrayJsonToCamelCase(gpayobj.allowed_payment_methods);
  paymentDataRequest.transactionInfo = Utils$OrcaPaymentPage.transformKeys(gpayobj.transaction_info, "CamelCase");
  paymentDataRequest.merchantInfo = Utils$OrcaPaymentPage.transformKeys(gpayobj.merchant_info, "CamelCase");
  paymentDataRequest.emailRequired = gpayobj.emailRequired;
  if (Utils$OrcaPaymentPage.getIsExpressCheckoutComponent(componentName)) {
    paymentDataRequest.shippingAddressRequired = gpayobj.shippingAddressRequired;
    paymentDataRequest.shippingAddressParameters = Utils$OrcaPaymentPage.transformKeys(gpayobj.shippingAddressParameters, "CamelCase");
  }
  return paymentDataRequest;
}

export {
  getLabel ,
  defaultTokenizationData ,
  defaultPaymentMethodData ,
  getTokenizationData ,
  getPaymentMethodData ,
  itemToObjMapper ,
  jsonToPaymentRequestDataType ,
  billingContactItemToObjMapper ,
  baseRequest ,
  getPaymentDataFromSession ,
}
/* Utils-OrcaPaymentPage Not a pure module */
