// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as PaymentHelpers$OrcaPaymentPage from "./PaymentHelpers.bs.js";

function taxResponseToObjMapper(resp) {
  var responseDict = Utils$OrcaPaymentPage.getDictFromJson(resp);
  var displayAmountDict = Utils$OrcaPaymentPage.getDictFromDict(responseDict, "display_amount");
  return {
          payment_id: Utils$OrcaPaymentPage.getString(responseDict, "payment_id", ""),
          net_amount: Utils$OrcaPaymentPage.getFloat(displayAmountDict, "net_amount", 0.0),
          order_tax_amount: Utils$OrcaPaymentPage.getFloat(displayAmountDict, "order_tax_amount", 0.0),
          shipping_cost: Utils$OrcaPaymentPage.getFloat(displayAmountDict, "shipping_cost", 0.0)
        };
}

function calculateTax(shippingAddress, logger, clientSecret, publishableKey, paymentMethodType) {
  return PaymentHelpers$OrcaPaymentPage.calculateTax(publishableKey, Utils$OrcaPaymentPage.getPaymentId(clientSecret), clientSecret, paymentMethodType, shippingAddress, logger, "");
}

export {
  taxResponseToObjMapper ,
  calculateTax ,
}
/* Utils-OrcaPaymentPage Not a pure module */
