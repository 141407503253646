// Generated by ReScript, PLEASE EDIT WITH CARE

import PackageJson from "/package.json";
import * as GlobalVars$OrcaPaymentPage from "./GlobalVars.bs.js";

function sendPostMessage(element, message) {
  element.postMessage(JSON.stringify(message), GlobalVars$OrcaPaymentPage.targetOrigin);
}

function iframePostMessage(iframeRef, message) {
  if (iframeRef == null) {
    console.error("This element does not exist or is not mounted yet.");
    return ;
  }
  try {
    return sendPostMessage(iframeRef.contentWindow, message);
  }
  catch (exn){
    return ;
  }
}

var version = PackageJson.version;

var $$Navigator = {};

var $$Location = {};

var $$Element = {};

var hrefWithoutSearch = window.location.origin + window.location.pathname;

var isSandbox = window.location.hostname === "beta.hyperswitch.io";

var isInteg = window.location.hostname === "dev.hyperswitch.io";

var isProd = window.location.hostname === "checkout.hyperswitch.io";

export {
  sendPostMessage ,
  iframePostMessage ,
  version ,
  $$Navigator ,
  $$Location ,
  $$Element ,
  hrefWithoutSearch ,
  isSandbox ,
  isInteg ,
  isProd ,
}
/* version Not a pure module */
