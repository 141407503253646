// Generated by ReScript, PLEASE EDIT WITH CARE


var This = {};

function oneClickConfirmPaymentFn(param, param$1) {
  return Promise.resolve({});
}

function confirmPaymentFn(_elements) {
  return Promise.resolve({});
}

function confirmCardPaymentFn(_clientSecretId, _data, _options) {
  return Promise.resolve({});
}

function retrievePaymentIntentFn(_paymentIntentId) {
  return Promise.resolve({});
}

function update(_options) {
  
}

function getElement(_componentName) {
  
}

function fetchUpdates() {
  return new Promise((function (resolve, param) {
                setTimeout((function () {
                        resolve({});
                      }), 1000);
              }));
}

function defaultPaymentElement_on(_str, _func) {
  
}

function defaultPaymentElement_collapse() {
  
}

function defaultPaymentElement_blur() {
  
}

function defaultPaymentElement_update(_x) {
  
}

function defaultPaymentElement_destroy() {
  
}

function defaultPaymentElement_unmount() {
  
}

function defaultPaymentElement_mount(_string) {
  
}

function defaultPaymentElement_focus() {
  
}

function defaultPaymentElement_clear() {
  
}

var defaultPaymentElement = {
  on: defaultPaymentElement_on,
  collapse: defaultPaymentElement_collapse,
  blur: defaultPaymentElement_blur,
  update: defaultPaymentElement_update,
  destroy: defaultPaymentElement_destroy,
  unmount: defaultPaymentElement_unmount,
  mount: defaultPaymentElement_mount,
  focus: defaultPaymentElement_focus,
  clear: defaultPaymentElement_clear
};

function create(_componentType, _options) {
  return defaultPaymentElement;
}

var defaultElement = {
  getElement: getElement,
  update: update,
  fetchUpdates: fetchUpdates,
  create: create
};

function getCustomerDefaultSavedPaymentMethodData() {
  return null;
}

function getSavedPaymentManagementMethodsList() {
  return null;
}

function deleteSavedPaymentMethod() {
  return null;
}

function getCustomerLastUsedPaymentMethodData() {
  return null;
}

function confirmWithCustomerDefaultPaymentMethod(_confirmParams) {
  return Promise.resolve({});
}

function confirmWithLastUsedPaymentMethod(_confirmParams) {
  return Promise.resolve({});
}

function defaultGetCustomerSavedPaymentMethods() {
  return Promise.resolve(null);
}

function defaultGetPaymentManagementMethods() {
  return Promise.resolve(null);
}

var defaultInitPaymentSession = {
  getCustomerSavedPaymentMethods: defaultGetCustomerSavedPaymentMethods,
  getPaymentManagementMethods: defaultGetPaymentManagementMethods
};

function defaultHyperInstance_elements(_ev) {
  return defaultElement;
}

function defaultHyperInstance_widgets(_ev) {
  return defaultElement;
}

function defaultHyperInstance_paymentRequest(_ev) {
  return null;
}

function defaultHyperInstance_initPaymentSession(_ev) {
  return defaultInitPaymentSession;
}

function defaultHyperInstance_paymentMethodsManagementElements(_ev) {
  return defaultElement;
}

var defaultHyperInstance = {
  confirmOneClickPayment: oneClickConfirmPaymentFn,
  confirmPayment: confirmPaymentFn,
  elements: defaultHyperInstance_elements,
  confirmCardPayment: confirmCardPaymentFn,
  retrievePaymentIntent: retrievePaymentIntentFn,
  widgets: defaultHyperInstance_widgets,
  paymentRequest: defaultHyperInstance_paymentRequest,
  initPaymentSession: defaultHyperInstance_initPaymentSession,
  paymentMethodsManagementElements: defaultHyperInstance_paymentMethodsManagementElements
};

function eventTypeMapper($$event) {
  switch ($$event) {
    case "blur" :
        return "Blur";
    case "change" :
        return "Change";
    case "clickTriggered" :
        return "Click";
    case "confirmTriggered" :
        return "ConfirmPayment";
    case "escape" :
        return "Escape";
    case "focus" :
        return "Focus";
    case "oneClickConfirmTriggered" :
        return "OneClickConfirmPayment";
    case "ready" :
        return "Ready";
    default:
      return "None";
  }
}

function getStrFromHyperComponentName(hyperComponentName) {
  if (hyperComponentName === "Elements") {
    return "Elements";
  } else {
    return "PaymentMethodsManagementElements";
  }
}

function getHyperComponentNameFromStr(hyperComponentName) {
  if (hyperComponentName === "PaymentMethodsManagementElements") {
    return "PaymentMethodsManagementElements";
  } else {
    return "Elements";
  }
}

export {
  This ,
  oneClickConfirmPaymentFn ,
  confirmPaymentFn ,
  confirmCardPaymentFn ,
  retrievePaymentIntentFn ,
  update ,
  getElement ,
  fetchUpdates ,
  defaultPaymentElement ,
  create ,
  defaultElement ,
  getCustomerDefaultSavedPaymentMethodData ,
  getSavedPaymentManagementMethodsList ,
  deleteSavedPaymentMethod ,
  getCustomerLastUsedPaymentMethodData ,
  confirmWithCustomerDefaultPaymentMethod ,
  confirmWithLastUsedPaymentMethod ,
  defaultGetCustomerSavedPaymentMethods ,
  defaultGetPaymentManagementMethods ,
  defaultInitPaymentSession ,
  defaultHyperInstance ,
  eventTypeMapper ,
  getStrFromHyperComponentName ,
  getHyperComponentNameFromStr ,
}
/* No side effect */
