// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function jsonToPaymentRequestDataType(jsonDict) {
  var clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var clientCountry = Utils$OrcaPaymentPage.getClientCountry(clientTimeZone);
  var defaultCountryCode = clientCountry.isoAlpha2;
  var getTotal = function (totalDict) {
    if (Utils$OrcaPaymentPage.getString(totalDict, "type", "") === "") {
      return {
              label: Utils$OrcaPaymentPage.getString(totalDict, "label", ""),
              amount: Utils$OrcaPaymentPage.getString(totalDict, "amount", "")
            };
    } else {
      return {
              label: Utils$OrcaPaymentPage.getString(totalDict, "label", ""),
              type: Utils$OrcaPaymentPage.getString(totalDict, "type", ""),
              amount: Utils$OrcaPaymentPage.getString(totalDict, "amount", "")
            };
    }
  };
  if (Utils$OrcaPaymentPage.getString(jsonDict, "merchant_identifier", "") === "") {
    return {
            countryCode: Utils$OrcaPaymentPage.getString(jsonDict, "country_code", defaultCountryCode),
            currencyCode: Utils$OrcaPaymentPage.getString(jsonDict, "currency_code", ""),
            total: getTotal(Utils$OrcaPaymentPage.getDictFromObj(jsonDict, "total")),
            merchantCapabilities: Utils$OrcaPaymentPage.getStrArray(jsonDict, "merchant_capabilities"),
            supportedNetworks: Utils$OrcaPaymentPage.getStrArray(jsonDict, "supported_networks")
          };
  } else {
    return {
            countryCode: Utils$OrcaPaymentPage.getString(jsonDict, "country_code", ""),
            currencyCode: Utils$OrcaPaymentPage.getString(jsonDict, "currency_code", ""),
            total: getTotal(Utils$OrcaPaymentPage.getDictFromObj(jsonDict, "total")),
            merchantCapabilities: Utils$OrcaPaymentPage.getStrArray(jsonDict, "merchant_capabilities"),
            supportedNetworks: Utils$OrcaPaymentPage.getStrArray(jsonDict, "supported_networks"),
            merchantIdentifier: Utils$OrcaPaymentPage.getString(jsonDict, "merchant_identifier", "")
          };
  }
}

function billingContactItemToObjMapper(dict) {
  return {
          addressLines: Utils$OrcaPaymentPage.getStrArray(dict, "addressLines"),
          administrativeArea: Utils$OrcaPaymentPage.getString(dict, "administrativeArea", ""),
          countryCode: Utils$OrcaPaymentPage.getString(dict, "countryCode", ""),
          familyName: Utils$OrcaPaymentPage.getString(dict, "familyName", ""),
          givenName: Utils$OrcaPaymentPage.getString(dict, "givenName", ""),
          locality: Utils$OrcaPaymentPage.getString(dict, "locality", ""),
          postalCode: Utils$OrcaPaymentPage.getString(dict, "postalCode", "")
        };
}

function shippingContactItemToObjMapper(dict) {
  return {
          emailAddress: Utils$OrcaPaymentPage.getString(dict, "emailAddress", ""),
          phoneNumber: Utils$OrcaPaymentPage.getString(dict, "phoneNumber", ""),
          addressLines: Utils$OrcaPaymentPage.getStrArray(dict, "addressLines"),
          administrativeArea: Utils$OrcaPaymentPage.getString(dict, "administrativeArea", ""),
          countryCode: Utils$OrcaPaymentPage.getString(dict, "countryCode", ""),
          familyName: Utils$OrcaPaymentPage.getString(dict, "familyName", ""),
          givenName: Utils$OrcaPaymentPage.getString(dict, "givenName", ""),
          locality: Utils$OrcaPaymentPage.getString(dict, "locality", ""),
          postalCode: Utils$OrcaPaymentPage.getString(dict, "postalCode", "")
        };
}

function getPaymentRequestFromSession(sessionObj, componentName) {
  var paymentRequest = Utils$OrcaPaymentPage.transformKeys(Core__Option.getOr(Core__Option.getOr(Core__Option.flatMap(sessionObj, Core__JSON.Decode.object), {})["payment_request_data"], {}), "CamelCase");
  var requiredShippingContactFields = Utils$OrcaPaymentPage.getStrArray(Utils$OrcaPaymentPage.getDictFromJson(paymentRequest), "requiredShippingContactFields");
  if (!Utils$OrcaPaymentPage.getIsExpressCheckoutComponent(componentName) && requiredShippingContactFields.length !== 0) {
    var shippingFieldsWithoutPostalAddress = requiredShippingContactFields.filter(function (item) {
          return item !== "postalAddress";
        });
    Utils$OrcaPaymentPage.getDictFromJson(paymentRequest)["requiredShippingContactFields"] = Utils$OrcaPaymentPage.getArrofJsonString(shippingFieldsWithoutPostalAddress);
  }
  return paymentRequest;
}

export {
  jsonToPaymentRequestDataType ,
  billingContactItemToObjMapper ,
  shippingContactItemToObjMapper ,
  getPaymentRequestFromSession ,
}
/* Utils-OrcaPaymentPage Not a pure module */
