// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__Int from "@rescript/core/src/Core__Int.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Icon$OrcaPaymentPage from "./Icon.bs.js";
import * as Utils$OrcaPaymentPage from "./Utilities/Utils.bs.js";
import * as CardPattern$OrcaPaymentPage from "./CardPattern.bs.js";
import * as PaymentType$OrcaPaymentPage from "./Types/PaymentType.bs.js";
import * as CardThemeType$OrcaPaymentPage from "./Types/CardThemeType.bs.js";

function toInt(val) {
  return Core__Option.getOr(Core__Int.fromString(undefined, val), 0);
}

function toString(val) {
  return val.toString();
}

function getQueryParamsDictforKey(searchParams, keyName) {
  var dict = {};
  searchParams.split("&").forEach(function (paramStr) {
        var keyValArr = paramStr.split("=");
        var key = Core__Option.getOr(keyValArr[0], "");
        var value = keyValArr.length > 0 ? Core__Option.getOr(keyValArr[1], "") : "";
        dict[key] = value;
      });
  return Core__Option.getOr(dict[keyName], "");
}

function getCardType(val) {
  switch (val) {
    case "AmericanExpress" :
        return "AMEX";
    case "BAJAJ" :
        return "BAJAJ";
    case "CartesBancaires" :
        return "CARTESBANCAIRES";
    case "DinersClub" :
        return "DINERSCLUB";
    case "Discover" :
        return "DISCOVER";
    case "Interac" :
        return "INTERAC";
    case "JCB" :
        return "JCB";
    case "Maestro" :
        return "MAESTRO";
    case "Mastercard" :
        return "MASTERCARD";
    case "RuPay" :
        return "RUPAY";
    case "SODEXO" :
        return "SODEXO";
    case "UnionPay" :
        return "UNIONPAY";
    case "Visa" :
        return "VISA";
    default:
      return "NOTFOUND";
  }
}

function getCardStringFromType(val) {
  switch (val) {
    case "VISA" :
        return "Visa";
    case "MASTERCARD" :
        return "Mastercard";
    case "AMEX" :
        return "AmericanExpress";
    case "MAESTRO" :
        return "Maestro";
    case "DINERSCLUB" :
        return "DinersClub";
    case "DISCOVER" :
        return "Discover";
    case "BAJAJ" :
        return "BAJAJ";
    case "SODEXO" :
        return "SODEXO";
    case "RUPAY" :
        return "RuPay";
    case "JCB" :
        return "JCB";
    case "CARTESBANCAIRES" :
        return "CartesBancaires";
    case "UNIONPAY" :
        return "UnionPay";
    case "INTERAC" :
        return "Interac";
    case "NOTFOUND" :
        return "NOTFOUND";
    
  }
}

function getobjFromCardPattern(cardBrand) {
  return Core__Option.getOr(CardPattern$OrcaPaymentPage.cardPatterns.filter(function (item) {
                    return cardBrand === item.issuer;
                  })[0], CardPattern$OrcaPaymentPage.defaultCardPattern);
}

function clearSpaces(value) {
  return value.replace(/\D+/g, "");
}

function slice(val, start, end) {
  return val.slice(start, end);
}

function getStrFromIndex(arr, index) {
  return Core__Option.getOr(arr[index], "");
}

function formatCVCNumber(val, cardType) {
  var clearValue = clearSpaces(val);
  var obj = getobjFromCardPattern(cardType);
  return clearValue.slice(0, obj.maxCVCLenth);
}

function getCurrentMonthAndYear(dateTimeIsoString) {
  var tempTimeDateString = dateTimeIsoString.replace("Z", "");
  var tempTimeDate = tempTimeDateString.split("T");
  var date = Core__Option.getOr(tempTimeDate[0], "");
  var dateComponents = date.split("-");
  var currentMonth = Core__Option.getOr(dateComponents[1], "");
  var currentYear = Core__Option.getOr(dateComponents[0], "");
  return [
          toInt(currentMonth),
          toInt(currentYear)
        ];
}

function formatCardNumber(val, cardType) {
  var clearValue = clearSpaces(val);
  var tmp;
  var exit = 0;
  switch (cardType) {
    case "AMEX" :
        tmp = clearValue.slice(0, 4) + " " + clearValue.slice(4, 10) + " " + clearValue.slice(10, 15);
        break;
    case "DINERSCLUB" :
        tmp = clearValue.slice(0, 4) + " " + clearValue.slice(4, 10) + " " + clearValue.slice(10, 14);
        break;
    case "VISA" :
    case "MASTERCARD" :
    case "DISCOVER" :
    case "SODEXO" :
    case "RUPAY" :
        exit = 1;
        break;
    default:
      tmp = clearValue.slice(0, 4) + " " + clearValue.slice(4, 8) + " " + clearValue.slice(8, 12) + " " + clearValue.slice(12, 19);
  }
  if (exit === 1) {
    tmp = clearValue.slice(0, 4) + " " + clearValue.slice(4, 8) + " " + clearValue.slice(8, 12) + " " + clearValue.slice(12, 16) + " " + clearValue.slice(16, 19);
  }
  return tmp.trim();
}

function splitExpiryDates(val) {
  var split = val.split("/");
  var value = split.map(function (item) {
        return item.trim();
      });
  var month = Core__Option.getOr(value[0], "");
  var year = Core__Option.getOr(value[1], "");
  return [
          month,
          year
        ];
}

function getExpiryDates(val) {
  var date = new Date().toISOString();
  var match = splitExpiryDates(val);
  var match$1 = getCurrentMonthAndYear(date);
  var prefix = match$1[1].toString().slice(0, 2);
  return [
          match[0],
          prefix + match[1]
        ];
}

function formatExpiryToTwoDigit(expiry) {
  if (expiry.length === 2) {
    return expiry;
  } else {
    return expiry.slice(2, 4);
  }
}

function isExpiryComplete(val) {
  var match = splitExpiryDates(val);
  if (match[0].length === 2) {
    return match[1].length === 2;
  } else {
    return false;
  }
}

function formatCardExpiryNumber(val) {
  var clearValue = clearSpaces(val);
  var expiryVal = toInt(clearValue);
  var formatted;
  if (expiryVal >= 2 && expiryVal <= 9 && clearValue.length === 1) {
    formatted = "0" + clearValue + " / ";
  } else if (clearValue.length === 2 && expiryVal > 12) {
    var val$1 = clearValue.split("");
    formatted = "0" + getStrFromIndex(val$1, 0) + " / " + getStrFromIndex(val$1, 1);
  } else {
    formatted = clearValue;
  }
  if (clearValue.length >= 3) {
    return formatted.slice(0, 2) + " / " + formatted.slice(2, 4);
  } else {
    return formatted;
  }
}

function getCardBrand(cardNumber) {
  try {
    var card = cardNumber.replace(/[^\d]/g, "");
    var rupayRanges = [
      [
        508227,
        508227
      ],
      [
        508500,
        508999
      ],
      [
        603741,
        603741
      ],
      [
        606985,
        607384
      ],
      [
        607385,
        607484
      ],
      [
        607485,
        607984
      ],
      [
        608001,
        608100
      ],
      [
        608101,
        608200
      ],
      [
        608201,
        608300
      ],
      [
        608301,
        608350
      ],
      [
        608351,
        608500
      ],
      [
        652150,
        652849
      ],
      [
        652850,
        653049
      ],
      [
        653050,
        653149
      ],
      [
        817290,
        817290
      ]
    ];
    var masterCardRanges = [
      [
        222100,
        272099
      ],
      [
        510000,
        559999
      ]
    ];
    var doesFallInRange = function (cardRanges, isin) {
      var intIsin = Core__Option.getOr(Core__Int.fromString(undefined, isin.replace(/[^\d]/g, "").substring(0, 6)), 0);
      var range = cardRanges.map(function (cardRange) {
            if (intIsin >= cardRange[0]) {
              return intIsin <= cardRange[1];
            } else {
              return false;
            }
          });
      return range.includes(true);
    };
    if (doesFallInRange(rupayRanges, card)) {
      return "RuPay";
    } else if (doesFallInRange(masterCardRanges, card)) {
      return "Mastercard";
    } else {
      return Core__Option.getOr(CardPattern$OrcaPaymentPage.cardPatterns.map(function (item) {
                          if (Core__Option.isSome(Caml_option.nullable_to_opt(card.match(item.pattern)))) {
                            return item.issuer;
                          } else {
                            return "";
                          }
                        }).filter(function (item) {
                        return item !== "";
                      })[0], "");
    }
  }
  catch (_error){
    return "";
  }
}

function calculateLuhn(value) {
  var card = clearSpaces(value);
  var splitArr = card.split("");
  splitArr.reverse();
  var unCheckArr = splitArr.filter(function (param, i) {
        return i % 2 === 0;
      });
  var checkArr = splitArr.filter(function (param, i) {
          return (i + 1 | 0) % 2 === 0;
        }).map(function (item) {
        var val = toInt(item);
        var $$double = (val << 1);
        var str = $$double.toString();
        var arr = str.split("");
        var match = arr[0];
        var match$1 = arr[1];
        if (match !== undefined && match$1 !== undefined && $$double > 9) {
          return (toInt(match) + toInt(match$1) | 0).toString();
        } else {
          return str;
        }
      });
  var sumofCheckArr = Core__Array.reduce(checkArr, 0, (function (acc, val) {
          return acc + toInt(val) | 0;
        }));
  var sumofUnCheckedArr = Core__Array.reduce(unCheckArr, 0, (function (acc, val) {
          return acc + toInt(val) | 0;
        }));
  var totalSum = sumofCheckArr + sumofUnCheckedArr | 0;
  if (totalSum % 10 === 0) {
    return true;
  } else {
    return [
              "3000100811111072",
              "4000100511112003"
            ].includes(card);
  }
}

function getCardBrandIcon(cardType, paymentType) {
  switch (cardType) {
    case "VISA" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "visa-light",
                    size: Utils$OrcaPaymentPage.brandIconSize
                  });
    case "MASTERCARD" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "mastercard",
                    size: Utils$OrcaPaymentPage.brandIconSize
                  });
    case "AMEX" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "amex-light",
                    size: Utils$OrcaPaymentPage.brandIconSize
                  });
    case "MAESTRO" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "maestro",
                    size: Utils$OrcaPaymentPage.brandIconSize
                  });
    case "DINERSCLUB" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "diners",
                    size: Utils$OrcaPaymentPage.brandIconSize
                  });
    case "DISCOVER" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "discover",
                    size: Utils$OrcaPaymentPage.brandIconSize
                  });
    case "RUPAY" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "rupay-card",
                    size: Utils$OrcaPaymentPage.brandIconSize
                  });
    case "JCB" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "jcb-card",
                    size: Utils$OrcaPaymentPage.brandIconSize
                  });
    case "BAJAJ" :
    case "SODEXO" :
    case "CARTESBANCAIRES" :
    case "UNIONPAY" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "card",
                    size: Utils$OrcaPaymentPage.brandIconSize
                  });
    case "INTERAC" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "interac",
                    size: Utils$OrcaPaymentPage.brandIconSize
                  });
    case "NOTFOUND" :
        if (paymentType === "Payment") {
          return React.createElement(Icon$OrcaPaymentPage.make, {
                      name: "base-card",
                      size: Utils$OrcaPaymentPage.brandIconSize
                    });
        } else {
          return React.createElement(Icon$OrcaPaymentPage.make, {
                      name: "default-card",
                      size: Utils$OrcaPaymentPage.brandIconSize
                    });
        }
    
  }
}

function getExpiryValidity(cardExpiry) {
  var date = new Date().toISOString();
  var match = getExpiryDates(cardExpiry);
  var year = match[1];
  var month = match[0];
  var match$1 = getCurrentMonthAndYear(date);
  var currentYear = match$1[1];
  if (currentYear === toInt(year) && toInt(month) >= match$1[0] && toInt(month) <= 12) {
    return true;
  } else if (toInt(year) > currentYear && toInt(year) < 2075 && toInt(month) >= 1) {
    return toInt(month) <= 12;
  } else {
    return false;
  }
}

function isExipryValid(val) {
  if (val.length > 0 && getExpiryValidity(val)) {
    return isExpiryComplete(val);
  } else {
    return false;
  }
}

function cardNumberInRange(val) {
  var clearValue = clearSpaces(val);
  var obj = getobjFromCardPattern(getCardBrand(val));
  return obj.length.map(function (item) {
              return clearValue.length === item;
            });
}

function max(a, b) {
  return Math.max(a, b);
}

function getMaxLength(val) {
  var obj = getobjFromCardPattern(getCardBrand(val));
  var maxValue = Core__Array.reduce(obj.length, 0, max);
  if (maxValue <= 12) {
    return maxValue + 2 | 0;
  } else if (maxValue <= 16) {
    return maxValue + 3 | 0;
  } else if (maxValue <= 19) {
    return maxValue + 4 | 0;
  } else {
    return maxValue + 2 | 0;
  }
}

function cvcNumberInRange(val, cardBrand) {
  var clearValue = clearSpaces(val);
  var obj = getobjFromCardPattern(cardBrand);
  return obj.cvcLength.map(function (item) {
              return clearValue.length === item;
            });
}

function genreateFontsLink(fonts) {
  if (fonts.length > 0) {
    fonts.map(function (item) {
          if (item.cssSrc !== "") {
            var link = document.createElement("link");
            link.href = item.cssSrc;
            link.rel = "stylesheet";
            return document.body.appendChild(link);
          }
          if (!(item.family !== "" && item.src !== "")) {
            return ;
          }
          var newStyle = document.createElement("style");
          newStyle.appendChild(document.createTextNode("\
@font-face {\
    font-family: \"" + item.family + "\";\
    src: url(" + item.src + ");\
    font-weight: \"" + item.weight + "\";\
}\
"));
          document.body.appendChild(newStyle);
        });
    return ;
  }
  
}

function maxCardLength(cardBrand) {
  var obj = getobjFromCardPattern(cardBrand);
  return Core__Array.reduce(obj.length, 0, (function (acc, val) {
                return Math.max(acc, val);
              }));
}

function cardValid(cardNumber, cardBrand) {
  var clearValueLength = clearSpaces(cardNumber).length;
  if (clearValueLength === maxCardLength(cardBrand) || cardBrand === "Visa" && clearValueLength === 16) {
    return calculateLuhn(cardNumber);
  } else {
    return false;
  }
}

function blurRef(ref) {
  Core__Option.forEach(Caml_option.nullable_to_opt(ref.current), (function (input) {
          input.blur();
        }));
}

function handleInputFocus(currentRef, destinationRef) {
  var optionalRef = destinationRef.current;
  if (optionalRef == null) {
    return blurRef(currentRef);
  } else {
    Core__Option.forEach((optionalRef == null) ? undefined : Caml_option.some(optionalRef), (function (input) {
            input.focus();
          }));
    return ;
  }
}

function getCardElementValue(iframeId, key) {
  var firstIframeVal;
  if (parent.frames[0].name !== iframeId) {
    var dom = parent.frames[0].document.getElementById(key);
    firstIframeVal = (dom == null) ? "" : dom.value;
  } else {
    firstIframeVal = "";
  }
  var secondIframeVal;
  if (parent.frames[1].name !== iframeId) {
    var dom$1 = parent.frames[1].document.getElementById(key);
    secondIframeVal = (dom$1 == null) ? "" : dom$1.value;
  } else {
    secondIframeVal = "";
  }
  var thirdIframeVal;
  if (parent.frames[2].name !== iframeId) {
    var dom$2 = parent.frames[2].document.getElementById(key);
    thirdIframeVal = (dom$2 == null) ? "" : dom$2.value;
  } else {
    thirdIframeVal = "";
  }
  if (thirdIframeVal === "") {
    if (secondIframeVal === "") {
      return firstIframeVal;
    } else {
      return secondIframeVal;
    }
  } else {
    return thirdIframeVal;
  }
}

function checkCardCVC(cvcNumber, cardBrand) {
  if (cvcNumber.length > 0) {
    return cvcNumberInRange(cvcNumber, cardBrand).includes(true);
  } else {
    return false;
  }
}

function checkCardExpiry(expiry) {
  if (expiry.length > 0) {
    return getExpiryValidity(expiry);
  } else {
    return false;
  }
}

function getBoolOptionVal(boolOptionVal) {
  if (boolOptionVal !== undefined) {
    if (boolOptionVal) {
      return "valid";
    } else {
      return "invalid";
    }
  } else {
    return "";
  }
}

function commonKeyDownEvent(ev, srcRef, destRef, srcEle, destEle, setEle) {
  var key = ev.keyCode;
  if (key === 8 && srcEle === "") {
    handleInputFocus(srcRef, destRef);
    setEle(function (param) {
          return destEle.slice(0, -1);
        });
    ev.preventDefault();
    return ;
  }
  
}

function pincodeVisibility(cardNumber) {
  var brand = getCardBrand(cardNumber);
  return Core__Option.getOr(CardPattern$OrcaPaymentPage.cardPatterns.filter(function (obj) {
                    return obj.issuer === brand;
                  })[0], CardPattern$OrcaPaymentPage.defaultCardPattern).pincodeRequired;
}

function swapCardOption(cardOpts, dropOpts, selectedOption) {
  var popEle = cardOpts.pop();
  dropOpts.push(Core__Option.getOr(popEle, ""));
  cardOpts.push(selectedOption);
  var temp = dropOpts.filter(function (item) {
        return item !== selectedOption;
      });
  return [
          cardOpts,
          temp
        ];
}

function setCardValid(cardnumber, setIsCardValid) {
  var cardBrand = getCardBrand(cardnumber);
  if (cardValid(cardnumber, cardBrand)) {
    return setIsCardValid(function (param) {
                return true;
              });
  } else if (!cardValid(cardnumber, cardBrand) && cardnumber.length === maxCardLength(cardBrand)) {
    return setIsCardValid(function (param) {
                return false;
              });
  } else if (cardnumber.length !== maxCardLength(cardBrand)) {
    return setIsCardValid(function (param) {
                
              });
  } else {
    return ;
  }
}

function setExpiryValid(expiry, setIsExpiryValid) {
  if (isExipryValid(expiry)) {
    return setIsExpiryValid(function (param) {
                return true;
              });
  } else if (!getExpiryValidity(expiry) && isExpiryComplete(expiry)) {
    return setIsExpiryValid(function (param) {
                return false;
              });
  } else if (!isExpiryComplete(expiry)) {
    return setIsExpiryValid(function (param) {
                
              });
  } else {
    return ;
  }
}

function getLayoutClass(layout) {
  if (layout.TAG === "StringLayout") {
    return {
            defaultCollapsed: PaymentType$OrcaPaymentPage.defaultLayout.defaultCollapsed,
            radios: PaymentType$OrcaPaymentPage.defaultLayout.radios,
            spacedAccordionItems: PaymentType$OrcaPaymentPage.defaultLayout.spacedAccordionItems,
            maxAccordionItems: PaymentType$OrcaPaymentPage.defaultLayout.maxAccordionItems,
            type: layout._0
          };
  } else {
    return layout._0;
  }
}

function getAllBanknames(obj) {
  return Core__Array.reduce(obj, [], (function (acc, item) {
                item.map(function (val) {
                      acc.push(val);
                    });
                return acc;
              }));
}

var clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

var clientCountry = Utils$OrcaPaymentPage.getClientCountry(clientTimeZone);

function postalRegex(postalCodes, country) {
  var country$1 = Core__Option.getOr(country, clientCountry.isoAlpha2);
  return Utils$OrcaPaymentPage.getCountryPostal(country$1, postalCodes).regex;
}

function setRightIconForCvc(cardEmpty, cardInvalid, color, cardComplete) {
  if (cardEmpty) {
    return React.createElement(Icon$OrcaPaymentPage.make, {
                name: "cvc-empty",
                size: Utils$OrcaPaymentPage.brandIconSize
              });
  } else if (cardInvalid) {
    return React.createElement("div", {
                style: {
                  color: color
                }
              }, React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "cvc-invalid",
                    size: Utils$OrcaPaymentPage.brandIconSize
                  }));
  } else if (cardComplete) {
    return React.createElement(Icon$OrcaPaymentPage.make, {
                name: "cvc-complete",
                size: Utils$OrcaPaymentPage.brandIconSize
              });
  } else {
    return React.createElement(Icon$OrcaPaymentPage.make, {
                name: "cvc-empty",
                size: Utils$OrcaPaymentPage.brandIconSize
              });
  }
}

function useCardDetails(cvcNumber, isCvcValidValue, isCVCValid) {
  return React.useMemo((function () {
                var isCardDetailsEmpty = cvcNumber.length === 0;
                var isCardDetailsValid = isCvcValidValue === "valid";
                var isCardDetailsInvalid = isCvcValidValue === "invalid";
                return [
                        isCardDetailsEmpty,
                        isCardDetailsValid,
                        isCardDetailsInvalid
                      ];
              }), [
              cvcNumber,
              isCvcValidValue,
              isCVCValid
            ]);
}

function getWalletBrandIcon(customerMethod) {
  var match = customerMethod.paymentMethodType;
  var iconName;
  if (match !== undefined) {
    switch (match) {
      case "apple_pay" :
          iconName = "apple_pay_saved";
          break;
      case "google_pay" :
          iconName = "google_pay_saved";
          break;
      case "paypal" :
          iconName = "paypal";
          break;
      default:
        iconName = "default-card";
    }
  } else {
    iconName = "default-card";
  }
  return React.createElement(Icon$OrcaPaymentPage.make, {
              name: iconName,
              size: Utils$OrcaPaymentPage.brandIconSize
            });
}

function getPaymentMethodBrand(customerMethod) {
  var match = customerMethod.paymentMethod;
  if (match === "wallet") {
    return getWalletBrandIcon(customerMethod);
  }
  var ele = customerMethod.card.scheme;
  return getCardBrandIcon(getCardType(ele !== undefined ? ele : ""), CardThemeType$OrcaPaymentPage.getPaymentMode(""));
}

export {
  toInt ,
  toString ,
  getQueryParamsDictforKey ,
  getCardType ,
  getCardStringFromType ,
  getobjFromCardPattern ,
  clearSpaces ,
  slice ,
  getStrFromIndex ,
  formatCVCNumber ,
  getCurrentMonthAndYear ,
  formatCardNumber ,
  splitExpiryDates ,
  getExpiryDates ,
  formatExpiryToTwoDigit ,
  isExpiryComplete ,
  formatCardExpiryNumber ,
  getCardBrand ,
  calculateLuhn ,
  getCardBrandIcon ,
  getExpiryValidity ,
  isExipryValid ,
  cardNumberInRange ,
  max ,
  getMaxLength ,
  cvcNumberInRange ,
  genreateFontsLink ,
  maxCardLength ,
  cardValid ,
  blurRef ,
  handleInputFocus ,
  getCardElementValue ,
  checkCardCVC ,
  checkCardExpiry ,
  getBoolOptionVal ,
  commonKeyDownEvent ,
  pincodeVisibility ,
  swapCardOption ,
  setCardValid ,
  setExpiryValid ,
  getLayoutClass ,
  getAllBanknames ,
  clientTimeZone ,
  clientCountry ,
  postalRegex ,
  setRightIconForCvc ,
  useCardDetails ,
  getWalletBrandIcon ,
  getPaymentMethodBrand ,
}
/* clientTimeZone Not a pure module */
