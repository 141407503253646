// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as GlobalVars$OrcaPaymentPage from "../GlobalVars.bs.js";
import * as OrcaLogger$OrcaPaymentPage from "../orca-log-catcher/OrcaLogger.bs.js";
import * as ApiEndpoint$OrcaPaymentPage from "../Utilities/ApiEndpoint.bs.js";
import * as PaymentSessionMethods$OrcaPaymentPage from "./PaymentSessionMethods.bs.js";

function make(options, clientSecret, publishableKey, logger, ephemeralKey) {
  var logger$1 = Core__Option.getOr(logger, OrcaLogger$OrcaPaymentPage.defaultLoggerConfig);
  var switchToCustomPod = GlobalVars$OrcaPaymentPage.isInteg && Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(options), (function (x) {
                  return x["switchToCustomPod"];
                })), Core__JSON.Decode.bool), false);
  var endpoint = ApiEndpoint$OrcaPaymentPage.getApiEndPoint(publishableKey, undefined);
  return {
          getCustomerSavedPaymentMethods: (function () {
              return PaymentSessionMethods$OrcaPaymentPage.getCustomerSavedPaymentMethods(clientSecret, publishableKey, endpoint, logger$1, switchToCustomPod);
            }),
          getPaymentManagementMethods: (function () {
              return PaymentSessionMethods$OrcaPaymentPage.getPaymentManagementMethods(ephemeralKey, logger$1, switchToCustomPod, endpoint);
            })
        };
}

export {
  make ,
}
/* GlobalVars-OrcaPaymentPage Not a pure module */
