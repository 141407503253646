// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__Int from "@rescript/core/src/Core__Int.bs.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Icon$OrcaPaymentPage from "../Icon.bs.js";
import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as CardUtils$OrcaPaymentPage from "../CardUtils.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "./ErrorUtils.bs.js";
import * as EnglishLocale$OrcaPaymentPage from "../LocaleStrings/EnglishLocale.bs.js";
import * as PaymentMethodCollectTypes$OrcaPaymentPage from "../Types/PaymentMethodCollectTypes.bs.js";

function getNestedValue(dict, key) {
  var keys = key.split(".");
  var length = keys.length;
  var _currentDict = dict;
  var _index = 0;
  while(true) {
    var index = _index;
    var currentDict = _currentDict;
    if (index === (length - 1 | 0)) {
      return currentDict[keys[index]];
    }
    var keyPart = keys[index];
    var subDict = currentDict[keyPart];
    if (subDict === undefined) {
      return ;
    }
    var innerDict = Core__JSON.Decode.object(subDict);
    if (innerDict === undefined) {
      return ;
    }
    _index = index + 1 | 0;
    _currentDict = innerDict;
    continue ;
  };
}

function getOrCreateSubDict(dict, key) {
  var subDict = dict[key];
  if (subDict !== undefined) {
    var innerDict = Core__JSON.Decode.object(subDict);
    if (innerDict !== undefined) {
      return innerDict;
    }
    var newSubDict = {};
    dict[key] = newSubDict;
    return newSubDict;
  }
  var newSubDict$1 = {};
  dict[key] = newSubDict$1;
  return newSubDict$1;
}

function setNestedValue(dict, key, value) {
  var keys = key.split(".");
  var _currentDict = dict;
  var _index = 0;
  while(true) {
    var index = _index;
    var currentDict = _currentDict;
    if (index === (keys.length - 1 | 0)) {
      currentDict[keys[index]] = value;
      return ;
    }
    var keyPart = keys[index];
    var subDict = getOrCreateSubDict(currentDict, keyPart);
    _index = index + 1 | 0;
    _currentDict = subDict;
    continue ;
  };
}

function setValue(dict, key, value) {
  var pmdCopy = Object.assign({}, dict);
  setNestedValue(pmdCopy, key, value);
  return pmdCopy;
}

function getValue(dict, key) {
  return Core__Option.flatMap(getNestedValue(dict, key), Core__JSON.Decode.string);
}

function getPaymentMethod(paymentMethod) {
  switch (paymentMethod) {
    case "Card" :
        return "card";
    case "BankTransfer" :
        return "bank_transfer";
    case "Wallet" :
        return "wallet";
    
  }
}

function getPaymentMethodForPayoutsConfirm(paymentMethod) {
  switch (paymentMethod) {
    case "Card" :
        return "card";
    case "BankTransfer" :
        return "bank";
    case "Wallet" :
        return "wallet";
    
  }
}

function getPaymentMethodType(paymentMethodType) {
  switch (paymentMethodType.TAG) {
    case "Card" :
        if (paymentMethodType._0 === "Credit") {
          return "credit";
        } else {
          return "debit";
        }
    case "BankTransfer" :
        switch (paymentMethodType._0) {
          case "ACH" :
              return "ach";
          case "Bacs" :
              return "bacs";
          case "Sepa" :
              return "sepa";
          
        }
    case "Wallet" :
        switch (paymentMethodType._0) {
          case "Paypal" :
              return "paypal";
          case "Pix" :
              return "pix";
          case "Venmo" :
              return "venmo";
          
        }
    
  }
}

function getPaymentMethodLabel(paymentMethod) {
  switch (paymentMethod) {
    case "Card" :
        return "Card";
    case "BankTransfer" :
        return "Bank";
    case "Wallet" :
        return "Wallet";
    
  }
}

function getPaymentMethodTypeLabel(paymentMethodType) {
  switch (paymentMethodType.TAG) {
    case "Card" :
        return "Card";
    case "BankTransfer" :
        switch (paymentMethodType._0) {
          case "ACH" :
              return "ACH";
          case "Bacs" :
              return "Bacs";
          case "Sepa" :
              return "SEPA";
          
        }
    case "Wallet" :
        switch (paymentMethodType._0) {
          case "Paypal" :
              return "PayPal";
          case "Pix" :
              return "Pix";
          case "Venmo" :
              return "Venmo";
          
        }
    
  }
}

function getPaymentMethodDataFieldKey(key) {
  switch (key) {
    case "CardNumber" :
        return "card.cardNumber";
    case "CardExpDate" :
        return "card.cardExp";
    case "CardHolderName" :
        return "card.cardHolder";
    case "CardBrand" :
        return "card.brand";
    case "ACHRoutingNumber" :
        return "ach.routing";
    case "ACHAccountNumber" :
        return "ach.account";
    case "ACHBankName" :
        return "ach.bankName";
    case "ACHBankCity" :
        return "ach.bankCity";
    case "BacsSortCode" :
        return "bacs.sort";
    case "BacsAccountNumber" :
        return "bacs.account";
    case "BacsBankName" :
        return "bacs.bankName";
    case "BacsBankCity" :
        return "bacs.bankCity";
    case "SepaIban" :
        return "sepa.iban";
    case "SepaBic" :
        return "sepa.bic";
    case "SepaBankName" :
        return "sepa.bankName";
    case "SepaBankCity" :
        return "sepa.bankCity";
    case "SepaCountryCode" :
        return "sepa.countryCode";
    case "PaypalMail" :
        return "paypal.email";
    case "PaypalMobNumber" :
        return "paypal.phoneNumber";
    case "PixId" :
        return "pix.id";
    case "PixBankAccountNumber" :
        return "pix.account";
    case "PixBankName" :
        return "pix.bankName";
    case "VenmoMobNumber" :
        return "venmo.phoneNumber";
    
  }
}

function getPaymentMethodDataFieldLabel(key, localeString) {
  switch (key) {
    case "CardNumber" :
        return localeString.cardNumberLabel;
    case "CardExpDate" :
        return localeString.validThruText;
    case "CardHolderName" :
        return localeString.cardHolderName;
    case "CardBrand" :
        return "Misc.";
    case "ACHRoutingNumber" :
        return localeString.formFieldACHRoutingNumberLabel;
    case "BacsSortCode" :
        return localeString.sortCodeText;
    case "ACHAccountNumber" :
    case "BacsAccountNumber" :
        return localeString.accountNumberText;
    case "SepaIban" :
        return localeString.formFieldSepaIbanLabel;
    case "SepaBic" :
        return localeString.formFieldSepaBicLabel;
    case "ACHBankCity" :
    case "BacsBankCity" :
    case "SepaBankCity" :
        return localeString.formFieldBankCityLabel;
    case "SepaCountryCode" :
        return localeString.formFieldCountryCodeLabel;
    case "PaypalMail" :
        return localeString.emailLabel;
    case "PixId" :
        return localeString.formFieldPixIdLabel;
    case "PixBankAccountNumber" :
        return localeString.formFieldBankAccountNumberLabel;
    case "PaypalMobNumber" :
    case "VenmoMobNumber" :
        return localeString.formFieldPhoneNumberLabel;
    default:
      return localeString.formFieldBankNameLabel;
  }
}

function getPaymentMethodDataFieldPlaceholder(key, locale, constant) {
  switch (key) {
    case "CardNumber" :
        return constant.formFieldCardNumberPlaceholder;
    case "CardExpDate" :
        return locale.expiryPlaceholder;
    case "CardHolderName" :
        return locale.formFieldCardHoldernamePlaceholder;
    case "CardBrand" :
        return "Misc.";
    case "ACHRoutingNumber" :
        return constant.formFieldACHRoutingNumberPlaceholder;
    case "BacsSortCode" :
        return constant.formFieldSortCodePlaceholder;
    case "ACHAccountNumber" :
    case "BacsAccountNumber" :
        return constant.formFieldAccountNumberPlaceholder;
    case "SepaIban" :
        return constant.formFieldSepaIbanPlaceholder;
    case "SepaBic" :
        return constant.formFieldSepaBicPlaceholder;
    case "ACHBankCity" :
    case "BacsBankCity" :
    case "SepaBankCity" :
        return locale.formFieldBankCityPlaceholder;
    case "SepaCountryCode" :
        return locale.countryLabel;
    case "PaypalMail" :
        return locale.formFieldEmailPlaceholder;
    case "PixId" :
        return constant.formFieldPixIdPlaceholder;
    case "PixBankAccountNumber" :
        return constant.formFieldBankAccountNumberPlaceholder;
    case "PaypalMobNumber" :
    case "VenmoMobNumber" :
        return locale.formFieldPhoneNumberPlaceholder;
    default:
      return locale.formFieldBankNamePlaceholder;
  }
}

function getPaymentMethodDataFieldMaxLength(key) {
  switch (key) {
    case "CardNumber" :
        return 23;
    case "CardExpDate" :
        return 7;
    case "ACHRoutingNumber" :
        return 9;
    case "ACHAccountNumber" :
        return 12;
    case "BacsSortCode" :
        return 6;
    case "BacsAccountNumber" :
        return 18;
    case "SepaIban" :
        return 34;
    case "SepaBic" :
        return 8;
    default:
      return 32;
  }
}

function getPaymentMethodDataFieldCharacterPattern(key) {
  switch (key) {
    case "CardNumber" :
        return Caml_option.some(/^\d{1,18}$/);
    case "CardHolderName" :
        return Caml_option.some(/^([a-zA-Z]| ){1,32}$/);
    case "ACHRoutingNumber" :
        return Caml_option.some(/^\d{1,9}$/);
    case "ACHAccountNumber" :
        return Caml_option.some(/^\d{1,17}$/);
    case "BacsSortCode" :
        return Caml_option.some(/^\d{1,6}$/);
    case "BacsAccountNumber" :
        return Caml_option.some(/^\d{1,18}$/);
    case "SepaIban" :
        return Caml_option.some(/^([A-Z0-9]| ){1,34}$/);
    case "SepaBic" :
        return Caml_option.some(/^([A-Z0-9]| ){1,8}$/);
    case "PaypalMail" :
        return Caml_option.some(/^[a-zA-Z0-9._%+-]*[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]*$/);
    case "PaypalMobNumber" :
        return Caml_option.some(/^[0-9]{1,12}$/);
    default:
      return ;
  }
}

function getPaymentMethodDataFieldInputType(key) {
  switch (key) {
    case "PaypalMail" :
        return "email";
    case "CardNumber" :
    case "CardExpDate" :
    case "ACHRoutingNumber" :
    case "ACHAccountNumber" :
    case "BacsSortCode" :
    case "BacsAccountNumber" :
    case "PaypalMobNumber" :
    case "VenmoMobNumber" :
        return "tel";
    default:
      return "text";
  }
}

function getPayoutImageSource(payoutStatus) {
  switch (payoutStatus) {
    case "Success" :
        return "https://live.hyperswitch.io/payment-link-assets/success.png";
    case "Initiated" :
    case "Pending" :
    case "RequiresFulfillment" :
        return "https://live.hyperswitch.io/payment-link-assets/pending.png";
    default:
      return "https://live.hyperswitch.io/payment-link-assets/failed.png";
  }
}

function getPayoutReadableStatus(payoutStatus, localeString) {
  switch (payoutStatus) {
    case "Success" :
        return localeString.payoutStatusSuccessText;
    case "Initiated" :
    case "Pending" :
    case "RequiresFulfillment" :
        return localeString.payoutStatusPendingText;
    default:
      return localeString.payoutStatusFailedText;
  }
}

function getPayoutStatusString(payoutStatus) {
  switch (payoutStatus) {
    case "Success" :
        return "success";
    case "Failed" :
        return "failed";
    case "Cancelled" :
        return "expired";
    case "Initiated" :
        return "initiated";
    case "Expired" :
        return "cancelled";
    case "Reversed" :
        return "reversed";
    case "Pending" :
        return "pending";
    case "Ineligible" :
        return "ineligible";
    case "RequiresCreation" :
        return "requires_creation";
    case "RequiresConfirmation" :
        return "requires_confirmation";
    case "RequiresPayoutMethodData" :
        return "requires_payout_method_data";
    case "RequiresFulfillment" :
        return "requires_fulfillment";
    case "RequiresVendorAccountCreation" :
        return "requires_vendor_account_creation";
    
  }
}

function getPayoutStatusMessage(payoutStatus, localeString) {
  switch (payoutStatus) {
    case "Success" :
        return localeString.payoutStatusSuccessMessage;
    case "Initiated" :
    case "Pending" :
    case "RequiresFulfillment" :
        return localeString.payoutStatusPendingMessage;
    default:
      return localeString.payoutStatusFailedMessage;
  }
}

function getPaymentMethodDataErrorString(key, value, localeString) {
  var len = value.length;
  var notEmptyAndComplete = len <= 0 || len === getPaymentMethodDataFieldMaxLength(key);
  switch (key) {
    case "CardNumber" :
        return localeString.inValidCardErrorText;
    case "CardExpDate" :
        if (notEmptyAndComplete) {
          return localeString.pastExpiryErrorText;
        } else {
          return localeString.inCompleteExpiryErrorText;
        }
    case "ACHRoutingNumber" :
        if (notEmptyAndComplete) {
          return "";
        } else {
          return localeString.formFieldInvalidRoutingNumber;
        }
    default:
      return "";
  }
}

function getPaymentMethodIcon(paymentMethod) {
  switch (paymentMethod) {
    case "Card" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "default-card",
                    size: 20
                  });
    case "BankTransfer" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "bank",
                    size: 20
                  });
    case "Wallet" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "wallet-generic-line",
                    size: 20
                  });
    
  }
}

function getBankTransferIcon(bankTransfer) {
  switch (bankTransfer) {
    case "ACH" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "ach_bank_transfer",
                    size: 20
                  });
    case "Bacs" :
    case "Sepa" :
        break;
    
  }
  return React.createElement(Icon$OrcaPaymentPage.make, {
              name: "bank",
              size: 20
            });
}

function getWalletIcon(wallet) {
  switch (wallet) {
    case "Paypal" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "wallet-paypal",
                    size: 20
                  });
    case "Pix" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "wallet-pix",
                    size: 20
                  });
    case "Venmo" :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "wallet-venmo",
                    size: 20
                  });
    
  }
}

function getPaymentMethodTypeIcon(paymentMethodType) {
  switch (paymentMethodType.TAG) {
    case "Card" :
        return getPaymentMethodIcon("Card");
    case "BankTransfer" :
        return getBankTransferIcon(paymentMethodType._0);
    case "Wallet" :
        return getWalletIcon(paymentMethodType._0);
    
  }
}

var defaultAmount = "0.01";

var defaultCurrency = "EUR";

var defaultEnabledPaymentMethods = [
  {
    TAG: "Card",
    _0: "Credit"
  },
  {
    TAG: "Card",
    _0: "Debit"
  },
  {
    TAG: "BankTransfer",
    _0: "ACH"
  },
  {
    TAG: "BankTransfer",
    _0: "Bacs"
  },
  {
    TAG: "BankTransfer",
    _0: "Sepa"
  },
  {
    TAG: "Wallet",
    _0: "Paypal"
  }
];

var defaultPaymentMethodCollectOptions = {
  enabledPaymentMethods: defaultEnabledPaymentMethods,
  linkId: "",
  payoutId: "",
  customerId: "",
  theme: "#1A1A1A",
  collectorName: "HyperSwitch",
  logo: "",
  returnUrl: undefined,
  amount: defaultAmount,
  currency: defaultCurrency,
  flow: "PayoutLinkInitiate",
  sessionExpiry: "",
  formLayout: "Tabs"
};

var defaultAvailablePaymentMethods = [];

var defaultAvailablePaymentMethodTypes = [];

var defaultStatusInfo_message = EnglishLocale$OrcaPaymentPage.localeStrings.payoutStatusSuccessMessage;

var defaultStatusInfo = {
  status: "Success",
  payoutId: "",
  message: defaultStatusInfo_message,
  code: undefined,
  errorMessage: undefined,
  reason: undefined
};

function itemToObjMapper(dict, logger) {
  ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
        "enabledPaymentMethods",
        "linkId",
        "payoutId",
        "customerId",
        "theme",
        "collectorName",
        "logo",
        "returnUrl",
        "amount",
        "currency",
        "flow",
        "sessionExpiry",
        "formLayout"
      ], dict, "options", logger);
  var json = dict["enabledPaymentMethods"];
  return {
          enabledPaymentMethods: json !== undefined ? PaymentMethodCollectTypes$OrcaPaymentPage.decodePaymentMethodTypeArray(json) : defaultEnabledPaymentMethods,
          linkId: Utils$OrcaPaymentPage.getString(dict, "linkId", ""),
          payoutId: Utils$OrcaPaymentPage.getString(dict, "payoutId", ""),
          customerId: Utils$OrcaPaymentPage.getString(dict, "customerId", ""),
          theme: Utils$OrcaPaymentPage.getString(dict, "theme", ""),
          collectorName: Utils$OrcaPaymentPage.getString(dict, "collectorName", ""),
          logo: Utils$OrcaPaymentPage.getString(dict, "logo", ""),
          returnUrl: Core__Option.flatMap(dict["returnUrl"], Core__JSON.Decode.string),
          amount: PaymentMethodCollectTypes$OrcaPaymentPage.decodeAmount(dict, defaultAmount),
          currency: Utils$OrcaPaymentPage.getString(dict, "currency", defaultCurrency),
          flow: PaymentMethodCollectTypes$OrcaPaymentPage.decodeFlow(dict, "PayoutLinkInitiate"),
          sessionExpiry: Utils$OrcaPaymentPage.getString(dict, "sessionExpiry", ""),
          formLayout: PaymentMethodCollectTypes$OrcaPaymentPage.decodeFormLayout(dict, "Tabs")
        };
}

function calculateValidity(dict, key) {
  var value = Core__Option.getOr(getValue(dict, getPaymentMethodDataFieldKey(key)), "");
  switch (key) {
    case "CardNumber" :
        if (CardUtils$OrcaPaymentPage.cardNumberInRange(value).includes(true) && CardUtils$OrcaPaymentPage.calculateLuhn(value)) {
          return true;
        } else if (value.length === 0) {
          return ;
        } else {
          return false;
        }
    case "CardExpDate" :
        if (value.length > 0 && CardUtils$OrcaPaymentPage.getExpiryValidity(value)) {
          return true;
        } else if (value.length === 0) {
          return ;
        } else {
          return false;
        }
    case "ACHRoutingNumber" :
        if (value.length !== 9) {
          return false;
        }
        var match = Core__Int.fromString(undefined, value.charAt(0));
        var match$1 = Core__Int.fromString(undefined, value.charAt(3));
        var match$2 = Core__Int.fromString(undefined, value.charAt(6));
        var p1 = match !== undefined && match$1 !== undefined && match$2 !== undefined ? Math.imul(3, (match + match$1 | 0) + match$2 | 0) : undefined;
        var match$3 = Core__Int.fromString(undefined, value.charAt(1));
        var match$4 = Core__Int.fromString(undefined, value.charAt(4));
        var match$5 = Core__Int.fromString(undefined, value.charAt(7));
        var p2 = match$3 !== undefined && match$4 !== undefined && match$5 !== undefined ? Math.imul(7, (match$3 + match$4 | 0) + match$5 | 0) : undefined;
        var match$6 = Core__Int.fromString(undefined, value.charAt(2));
        var match$7 = Core__Int.fromString(undefined, value.charAt(5));
        var match$8 = Core__Int.fromString(undefined, value.charAt(8));
        var p3 = match$6 !== undefined && match$7 !== undefined && match$8 !== undefined ? (match$6 + match$7 | 0) + match$8 | 0 : undefined;
        if (p1 !== undefined && p2 !== undefined && p3 !== undefined) {
          return ((p1 + p2 | 0) + p3 | 0) % 10 === 0;
        } else {
          return false;
        }
    default:
      return ;
  }
}

function checkValidity(keys, fieldValidityDict) {
  return Core__Array.reduce(keys, true, (function (acc, key) {
                var validity = fieldValidityDict[key];
                if (validity !== undefined) {
                  if (acc) {
                    return Core__Option.getOr(Caml_option.valFromOption(validity), true);
                  } else {
                    return false;
                  }
                } else {
                  return acc;
                }
              }));
}

function formPaymentMethodData(paymentMethodType, paymentMethodDataDict, fieldValidityDict) {
  if (paymentMethodType === undefined) {
    return ;
  }
  switch (paymentMethodType.TAG) {
    case "Card" :
        var match = getValue(paymentMethodDataDict, "card.cardHolder");
        var match$1 = getValue(paymentMethodDataDict, "card.cardNumber");
        var match$2 = getValue(paymentMethodDataDict, "card.cardExp");
        if (match !== undefined && match$1 !== undefined && match$2 !== undefined && checkValidity([
                "card.cardHolder",
                "card.cardNumber",
                "card.cardExp"
              ], fieldValidityDict)) {
          return [
                  "Card",
                  {
                    TAG: "Card",
                    _0: "Debit"
                  },
                  [
                    [
                      "CardHolderName",
                      match
                    ],
                    [
                      "CardNumber",
                      match$1
                    ],
                    [
                      "CardExpDate",
                      match$2
                    ]
                  ]
                ];
        } else {
          return ;
        }
    case "BankTransfer" :
        switch (paymentMethodType._0) {
          case "ACH" :
              var match$3 = getValue(paymentMethodDataDict, "ach.routing");
              var match$4 = getValue(paymentMethodDataDict, "ach.account");
              var match$5 = getValue(paymentMethodDataDict, "ach.bankName");
              var match$6 = getValue(paymentMethodDataDict, "ach.bankCity");
              if (match$3 === undefined) {
                return ;
              }
              if (match$4 === undefined) {
                return ;
              }
              if (!checkValidity([
                      "ach.routing",
                      "ach.account",
                      "ach.bankName",
                      "ach.bankCity"
                    ], fieldValidityDict)) {
                return ;
              }
              var pmd = [
                [
                  "ACHRoutingNumber",
                  match$3
                ],
                [
                  "ACHAccountNumber",
                  match$4
                ]
              ];
              Core__Option.forEach(match$5, (function (bankName) {
                      pmd.push([
                            "ACHBankName",
                            bankName
                          ]);
                    }));
              Core__Option.forEach(match$6, (function (city) {
                      pmd.push([
                            "ACHBankCity",
                            city
                          ]);
                    }));
              return [
                      "BankTransfer",
                      {
                        TAG: "BankTransfer",
                        _0: "ACH"
                      },
                      pmd
                    ];
          case "Bacs" :
              var match$7 = getValue(paymentMethodDataDict, "bacs.sort");
              var match$8 = getValue(paymentMethodDataDict, "bacs.account");
              var match$9 = getValue(paymentMethodDataDict, "bacs.bankName");
              var match$10 = getValue(paymentMethodDataDict, "bacs.bankCity");
              if (match$7 === undefined) {
                return ;
              }
              if (match$8 === undefined) {
                return ;
              }
              if (!checkValidity([
                      "bacs.sort",
                      "bacs.account",
                      "bacs.bankName",
                      "bacs.bankCity"
                    ], fieldValidityDict)) {
                return ;
              }
              var pmd$1 = [
                [
                  "BacsSortCode",
                  match$7
                ],
                [
                  "BacsAccountNumber",
                  match$8
                ]
              ];
              Core__Option.forEach(match$9, (function (bankName) {
                      pmd$1.push([
                            "BacsBankName",
                            bankName
                          ]);
                    }));
              Core__Option.forEach(match$10, (function (city) {
                      pmd$1.push([
                            "BacsBankCity",
                            city
                          ]);
                    }));
              return [
                      "BankTransfer",
                      {
                        TAG: "BankTransfer",
                        _0: "Bacs"
                      },
                      pmd$1
                    ];
          case "Sepa" :
              var match$11 = getValue(paymentMethodDataDict, "sepa.iban");
              var match$12 = getValue(paymentMethodDataDict, "sepa.bic");
              var match$13 = getValue(paymentMethodDataDict, "sepa.bankName");
              var match$14 = getValue(paymentMethodDataDict, "sepa.bankCity");
              var match$15 = getValue(paymentMethodDataDict, "sepa.countryCode");
              if (match$11 === undefined) {
                return ;
              }
              if (!checkValidity([
                      "sepa.iban",
                      "sepa.bic",
                      "sepa.bankName",
                      "sepa.bankCity",
                      "sepa.countryCode"
                    ], fieldValidityDict)) {
                return ;
              }
              var pmd$2 = [[
                  "SepaIban",
                  match$11
                ]];
              Core__Option.forEach(match$12, (function (bic) {
                      pmd$2.push([
                            "SepaBic",
                            bic
                          ]);
                    }));
              Core__Option.forEach(match$13, (function (bankName) {
                      pmd$2.push([
                            "SepaBankName",
                            bankName
                          ]);
                    }));
              Core__Option.forEach(match$14, (function (city) {
                      pmd$2.push([
                            "SepaBankCity",
                            city
                          ]);
                    }));
              Core__Option.forEach(match$15, (function (countryCode) {
                      pmd$2.push([
                            "SepaCountryCode",
                            countryCode
                          ]);
                    }));
              return [
                      "BankTransfer",
                      {
                        TAG: "BankTransfer",
                        _0: "Sepa"
                      },
                      pmd$2
                    ];
          
        }
    case "Wallet" :
        switch (paymentMethodType._0) {
          case "Paypal" :
              var email = getValue(paymentMethodDataDict, "paypal.email");
              if (email !== undefined) {
                return [
                        "Wallet",
                        {
                          TAG: "Wallet",
                          _0: "Paypal"
                        },
                        [[
                            "PaypalMail",
                            email
                          ]]
                      ];
              } else {
                return ;
              }
          case "Pix" :
          case "Venmo" :
              return ;
          
        }
    
  }
}

function formBody(flow, paymentMethodData) {
  var pmdApiFields = [];
  paymentMethodData[2].forEach(function (field) {
        var value = field[1];
        var exit = 0;
        switch (field[0]) {
          case "CardNumber" :
              pmdApiFields.push([
                    "card_number",
                    value
                  ]);
              return ;
          case "CardExpDate" :
              var split = value.split("/");
              var match = split[0];
              var match$1 = split[1];
              if (match !== undefined && match$1 !== undefined) {
                pmdApiFields.push([
                      "expiry_month",
                      match
                    ]);
                pmdApiFields.push([
                      "expiry_year",
                      "20" + match$1
                    ]);
                return ;
              } else {
                return ;
              }
          case "CardHolderName" :
              pmdApiFields.push([
                    "card_holder_name",
                    value
                  ]);
              return ;
          case "CardBrand" :
              pmdApiFields.push([
                    "card_brand",
                    value
                  ]);
              return ;
          case "ACHRoutingNumber" :
              pmdApiFields.push([
                    "bank_routing_number",
                    value
                  ]);
              return ;
          case "BacsSortCode" :
              pmdApiFields.push([
                    "bank_sort_code",
                    value
                  ]);
              return ;
          case "SepaIban" :
              pmdApiFields.push([
                    "iban",
                    value
                  ]);
              return ;
          case "SepaBic" :
              pmdApiFields.push([
                    "bic",
                    value
                  ]);
              return ;
          case "ACHBankCity" :
          case "BacsBankCity" :
          case "SepaBankCity" :
              exit = 2;
              break;
          case "SepaCountryCode" :
              pmdApiFields.push([
                    "bank_country_code",
                    value
                  ]);
              return ;
          case "PaypalMail" :
              pmdApiFields.push([
                    "email",
                    value
                  ]);
              return ;
          case "PixId" :
              pmdApiFields.push([
                    "pix_key",
                    value
                  ]);
              return ;
          case "ACHAccountNumber" :
          case "BacsAccountNumber" :
          case "PixBankAccountNumber" :
              exit = 1;
              break;
          case "PaypalMobNumber" :
          case "VenmoMobNumber" :
              pmdApiFields.push([
                    "telephone_number",
                    value
                  ]);
              return ;
          default:
            pmdApiFields.push([
                  "bank_name",
                  value
                ]);
            return ;
        }
        switch (exit) {
          case 1 :
              pmdApiFields.push([
                    "bank_account_number",
                    value
                  ]);
              return ;
          case 2 :
              pmdApiFields.push([
                    "bank_city",
                    value
                  ]);
              return ;
          
        }
      });
  var tmp;
  tmp = flow === "PayoutLinkInitiate" ? getPaymentMethodForPayoutsConfirm : getPaymentMethod;
  var paymentMethod = tmp(paymentMethodData[0]);
  var pmdBody = Utils$OrcaPaymentPage.getJsonFromArrayOfJson(pmdApiFields.map(function (param) {
            return [
                    param[0],
                    param[1]
                  ];
          }));
  var body = [];
  if (flow === "PayoutLinkInitiate") {
    var pmd = {};
    pmd[paymentMethod] = pmdBody;
    body.push([
          "payout_type",
          paymentMethod
        ]);
    body.push([
          "payout_method_data",
          pmd
        ]);
  } else {
    body.push([
          "payment_method",
          paymentMethod
        ]);
    body.push([
          "payment_method_type",
          getPaymentMethodType(paymentMethodData[1])
        ]);
    body.push([
          paymentMethod,
          pmdBody
        ]);
  }
  return body;
}

var defaultFormLayout = "Tabs";

var defaultPaymentMethodCollectFlow = "PayoutLinkInitiate";

var defaultOptionsLimitInTabLayout = 2;

var defaultSelectedPaymentMethod;

var defaultSelectedPaymentMethodType;

export {
  getNestedValue ,
  getOrCreateSubDict ,
  setNestedValue ,
  setValue ,
  getValue ,
  getPaymentMethod ,
  getPaymentMethodForPayoutsConfirm ,
  getPaymentMethodType ,
  getPaymentMethodLabel ,
  getPaymentMethodTypeLabel ,
  getPaymentMethodDataFieldKey ,
  getPaymentMethodDataFieldLabel ,
  getPaymentMethodDataFieldPlaceholder ,
  getPaymentMethodDataFieldMaxLength ,
  getPaymentMethodDataFieldCharacterPattern ,
  getPaymentMethodDataFieldInputType ,
  getPayoutImageSource ,
  getPayoutReadableStatus ,
  getPayoutStatusString ,
  getPayoutStatusMessage ,
  getPaymentMethodDataErrorString ,
  getPaymentMethodIcon ,
  getBankTransferIcon ,
  getWalletIcon ,
  getPaymentMethodTypeIcon ,
  defaultFormLayout ,
  defaultPaymentMethodCollectFlow ,
  defaultAmount ,
  defaultCurrency ,
  defaultEnabledPaymentMethods ,
  defaultPaymentMethodCollectOptions ,
  defaultOptionsLimitInTabLayout ,
  defaultAvailablePaymentMethods ,
  defaultAvailablePaymentMethodTypes ,
  defaultSelectedPaymentMethod ,
  defaultSelectedPaymentMethodType ,
  defaultStatusInfo ,
  itemToObjMapper ,
  calculateValidity ,
  checkValidity ,
  formPaymentMethodData ,
  formBody ,
}
/* react Not a pure module */
